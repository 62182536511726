<style lang="scss" scoped>
.common-box{
    background: $white;
    padding: 24px;
    border-radius: 4px; 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .main{
        flex-grow: 1;
    }
    .title{
        flex-shrink: 0;
        font-size: 14px;
        margin-bottom: 12px;
    }
    .value{
        font-size: 36px;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: $black;
        margin-bottom: 12px;
    }
}


</style>

<template>
    <div class="common-box">
        <div class="title">
            <div>{{ title }}</div>
            <div></div>
        </div>
        <div class="main">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default{
    props:{
        title:String,
    }
}
</script>