<style lang="scss" scoped>
.little-box{
    background: $white;
    padding: 24px;
    border-radius: 4px; 
    flex: 1;
    margin-right: 24px;
    .title{
        font-size: 14px;
        margin-bottom: 12px;
    }
    .value{
        font-size: 36px;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: $black;
        margin-bottom: 12px;
    }
}

#specular{
    background: $blue;
    .title{
        color: $white;
    }
    .value{
        color: $white;
    }
}
</style>

<template>
    <div class="little-box" :id="specular?'specular':''">
        <div class="title">{{ title }}</div>
        <div class="value">
            <div class="unit" v-if="unit">{{ unit }}</div>
            <div class="" >{{ value }}</div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default{
    props:{
        title:String,
        value:{
            type:[Number,String],
            default:0
        },
        unit:{
            type:String,
            default:""
        },
        specular:{
            type:Boolean,
            default:false
        }
    },
}
</script>