import api from "@/lib/axios";

export const getTotalMoney = async () => {
  let result = {
    totalScales: 0,
    totalScales1: 0,
    totalScales2: 0,
    totalEmit: 0,
    totalEmit1: 0,
    totalEmit2: 0,
    profit: 0,
  };

  //业务项目
  {
    let res = await api.request({
      url: "business/getBusiness",
      params: {
        pageSize: 10000,
        pageNumber: 1,
        projectType: 0,
      },
    });

      let res1 = await api.request({
        url: "business/getBusiness",
        params: {
          pageSize: 10000,
          pageNumber: 1,
          projectType: 1,
        },
      });
    if (res.data) {
      result.totalScales += res.data.totalReceivable;
      result.totalScales1 += res.data.totalHaveReceived;
      result.totalScales2 += res.data.totalNoReceived;
      result.totalEmit += res.data.totalPayedMoney;
      let totalPay = 0;
      res.data.basicPageVo.list.map((e) => {
        totalPay += e.travelFee*1;
        totalPay += e.purchaseTotalAmounts*1;
        totalPay += e.businessEntertainmentFee*1;
      });

       res1.data.basicPageVo.list.map((e) => {
         totalPay += e.travelFee * 1;
         totalPay += e.purchaseTotalAmounts * 1;
         totalPay += e.businessEntertainmentFee * 1;
       });
      result.totalEmit1 += res.data.totalPayedMoney;
      result.totalEmit2 += totalPay - res.data.totalPayedMoney;
      // result.totalEmit1 += res.data.totalReceivable;
    }
  }


  
    // {
    //   let res = await api.request({
    //     url: "business/getBusiness",
    //     params: {
    //       pageSize: 10000,
    //       pageNumber: 1,
    //       projectType: 1,
    //     },
    //   });
    //   if (res.data) {
    //     result.totalScales += res.data.totalReceivable;
    //     result.totalScales1 += res.data.totalHaveReceived;
    //     result.totalScales2 += res.data.totalNoReceived;
    //     result.totalEmit += res.data.totalPayedMoney;
    //     let totalPay = 0;
    //     res.data.basicPageVo.list.map((e) => {
    //       totalPay += e.travelFee;
    //       totalPay += e.purchaseTotalAmounts;
    //       totalPay += e.businessEntertainmentFee;
    //     });

    //     result.totalEmit1 += res.data.totalPayedMoney;
    //     result.totalEmit2 += totalPay - res.data.totalPayedMoney;
    //     // result.totalEmit1 += res.data.totalReceivable;
    //   }
    // }

  //知识产权

  {
    let res = await api.request({
      url: "declare/getDeclare",
      params: {
        pageSize: 10000,
        pageNumber: 1,
      },
    });
    if (res.data) {
      result.totalScales += res.data.shouldReceivedTotal;
      result.totalScales1 += res.data.haveReceivedTotal;
      result.totalScales2 += res.data.noHaveReceivedTotal;

      let totalPay = 0;
      res.data.basicPageVo.list.map((e) => {
        totalPay += e.declarationFee;
        totalPay += e.purchaseFee;
        //  totalPay += e.purchaseFee;
        totalPay += e.travelFee;
        totalPay += e.businessEntertainmentFee;
      }); 

      result.totalEmit += totalPay;
      result.totalEmit1 += res.data.payedTotal;
      result.totalEmit2 += totalPay - res.data.payedTotal;
      // result.totalEmit1 += res.data.totalReceivable;
    }
  }

  //办公费
  {
    try {
      let res = await api.request({
        url: "office/getMoneyMessage",
      });
      res = res.data;
        let data = [
          {
            name: "用品采购",
            value: this.totalData.procurementMoney || 0,
          },
          {
            name: "房租费用",
            value: this.totalData?.rentMoney?.totalAmounts || 0,
            tooltip: {
              已付总额: this.totalData?.rentMoney?.payments || 0,
              未付总额: this.totalData?.rentMoney?.nonPaymentAmounts || 0,
            },
          },
          {
            name: "物业费",
            value: this.totalData?.propertyFee?.totalAmounts || 0,
            tooltip: {
              已付总额: this.totalData?.propertyFee?.payments || 0,
              未付总额: this.totalData?.propertyFee?.nonPaymentAmounts || 0,
            },
          },
          {
            name: "水电费",
            value: this.totalData?.waterAndElectricity?.totalAmounts || 0,
            tooltip: {
              已付总额: this.totalData?.waterAndElectricity?.payments,
              未付总额: this.totalData?.waterAndElectricity?.nonPaymentAmounts,
            },
          },
          {
            name: "手续费",
            value: this.totalData.serviceCharge || 0,
          },
            {
            name: "公关费",
            value: this.totalData.publicRelationSpending || 0,
          },
        ];
      let all =
        data[0].value +
        data[1].value +
        data[2].value +
        data[3].value +
        data[4].value+
        data[5].value;
      result.totalEmit += all;

      let have =
        data[0].value +
        data[4].value +
         0 + data[5].value ||
        0; 
        data[1].tooltip["已付总额"] +
        data[2].tooltip["已付总额"] +
        data[3].tooltip["已付总额"];
      result.totalEmit1 += have;
      result.totalEmit2 += all - have;
    } catch (error) {
      console.log(error);
    }
  }

  //固定资产
  {
    let res = await api.request({
      url: "fix/getFix?pageSize=10&pageNumber=1&total=6&name=&type=0",
    });
    if (res.data) {
      result.totalEmit += res.data.totalAmount;
      result.totalEmit1 += res.data.totalAmount;
    }
  }

  //福利费
  {
    let res = await api.request({
      url: "fix/getFix?pageSize=10&pageNumber=1&total=1000&name=&type=1",
    });
    if (res.data) {
      result.totalEmit += res.data.totalAmount;
      result.totalEmit1 += res.data.totalAmount;
    }
  }

  //人工费
  {
    let res = await api.request({
      url: "taxLabor/getTotalMoney",
    });
    if (res.data) {
      result.totalEmit += res.data.costLabor;
      result.totalEmit1 += res.data.costLabor;
      result.totalEmit += res.data.tax;
      result.totalEmit1 += res.data.tax;
    }
  }


  result.profit = result.totalScales1 - result.totalEmit1;

  return result;
};
