<style lang="scss">
.home {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .home-section-1 {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .little-box:last-child {
            margin-right: 0;
        }

        .sold-value {
            font-size: 16px;
            color: $grow-max;
            display: flex;
            align-items: center;

            .down {
                display: flex;
                margin-left: 4px;
                margin-right: 4px;

                svg {
                    stroke: green;
                }

                background: $green;
                border-radius: 50%;
            }

            .up {
                display: flex;
                margin-left: 4px;
                margin-right: 4px;
                background: $red;
                border-radius: 50%;
            }

            &.white {
                color: $grow-min;
            }
        }
    }

    .home-section-2 {
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        .box-1 {
            flex-shrink: 0;
            height: 400px;
            width: calc(100% - (100% - 96px) /5 - 24px);

            .days-chart {
                width: 100%;
                height: 100%;
            }
        }

        .box-2 {
            flex-grow: 1;
            margin-left: 24px;

            .days-chart {
                width: 100%;
                height: 100%;
            }
        }
    }

    .home-section-3 {
        display: flex;
        margin-top: 24px;

        .box-1 {
            flex-grow: 1;
            flex-shrink: 0;
            margin-right: 24px;
        }

        .box-2 {
            flex-grow: 1;
            flex-shrink: 0;
        }
    }
}

.red{
    color: $red;
}
</style>

<template>
    <div class="home">

        <!-- 数据总值 -->
        <div class="home-section-1">
            <little-box unit="￥" specular :value="result?.totalScales " title="销售总额">
                <div class="sold-value white">
                    <div>已收￥{{ result?.totalScales1.toFixed(2)  }}/ <span class="red">未收￥{{result?.totalScales2.toFixed(2) }}</span> </div>
                </div>
            </little-box>
            <little-box unit="￥" :value='result.totalEmit' title="采购总额">
                <div class="sold-value">
                    <div>已付￥{{ result?.totalEmit1.toFixed(2) }}/ <span class="red">未付￥{{ result?.totalEmit2.toFixed(2) }}</span></div>
                </div>
            </little-box>
            <little-box unit="￥" :value='result.profit.toFixed(2)' title="盈利总额">
            </little-box>
            <!-- <little-box :value="14" title="活跃客户">
                <div class="sold-value">

                    <div>自从上周 </div>
                    <div class="up" v-if="1 == 1">
                        <svg data-v-96d9c800="" width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path data-v-96d9c800="" d="M4.5 8L8 4.5L11.5 8" stroke="white" stroke-width="1.5">
                            </path>
                            <path data-v-96d9c800="" d="M8 5V12" stroke="white" stroke-width="1.5"></path>
                        </svg>
                    </div>
                    <div class="down" v-else>
                        <svg data-v-96d9c800="" width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path data-v-96d9c800="" d="M11.5 8L8 11.5L4.5 8" stroke="white" stroke-width="1.5">
                            </path>
                            <path data-v-96d9c800="" d="M8 11L8 4" stroke="white" stroke-width="1.5"></path>
                        </svg>
                    </div>
                    <div class="vlaue">{{ 50}}%</div>
                </div>
            </little-box>
            <little-box :value="14" title="合同数量">
                <div class="sold-value">
                    <div>已完成{{ 5 }}个/ <span class="red">未完成{{ 9 }}个</span> </div>
                </div>
            </little-box> -->
        </div>

        <!-- 区块2 -->
        <div class="home-section-2">
            <div class="box-1">
                <common-box title="历史销售/盈利总额（￥）">
                    <div class="days-chart">
                        <chart-base :options="daysData"></chart-base>
                    </div>
                </common-box>
            </div>
            <div class="box-2">
                <common-box title="销售/盈利占比">
                    <div class="days-chart">
                        <chart-base :options="perOptions"></chart-base>
                    </div>
                </common-box>
            </div>
        </div>
    </div>
</template>

<script>
import {getTotalMoney} from "@/api/computed"
import { BLUE, BLUE_1 } from "../../../../constant/system"
import commonBox from "./components/common-box"
import littleBox from "./components/little-box"
export default {
    data() {
        return {
            daysData: {
            },
            result:{
              totalScales: 0,
    totalScales1: 0,
    totalScales2: 0,
    totalEmit: 0,
    totalEmit1: 0,
    totalEmit2: 0,
    profit: 0,


            },
            perOptions: {

            },
        }
    },
    components: {
        littleBox, commonBox
    },
    watch:{
        result(){
            this.loadPerOption()
        }
    },      
    methods: {
        loadData() {
            this.loadDaysData()
        },
        async loadDaysData() {
            this.daysData = {
                title: {
                    show: false,
                },

                legend: {
                    orient: 'horizontal',
                    x: 'right',
                    y: 'top'
                },
                tooltip: {
                    trigger: "axis"
                },
                grid: {
                    top: '24px',
                    left: '0%',
                    right: '0%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: {
                    name: '月份',
                    type: 'category',
                    data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月',],
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        show: false
                    }
                },

                series: [
                    {
                        name: '销售',
                        data: [454, 226, 891, 978, 901, 581, 400, 543, 272, 955, 1294, 1581],
                        type: 'line',
                        smooth: true
                    },

                    {
                        name: '采购',
                        data: [2455, 2534, 2360, 2301, 2861, 2181, 1944, 2197, 1745, 1810, 2283, 2298],
                        type: 'line',
                        smooth: true
                    }
                ],
                color: [BLUE, BLUE_1]
            }
        },
        async loadPerOption() {
            this.perOptions = {
                tooltip: {

                },
                series: [
                    {
                        label: {
                            position: 'inner',
                            formatter: '{b}: ({d}%)',
                            textStyle: {
                                color: "white",
                                fontSize: 14
                            }
                        },
                        name: '金额占比',
                        type: 'pie',
                        radius: '80%',
                        data: [
                            { value: this.result.totalScales, name: '销售' },
                            { value: this.result.totalEmit, name: '采购' },
                        ],
                        color: [BLUE, BLUE_1]
                    }
                ]
            }
        },
        async getTotalMoney(){
            let data=await getTotalMoney()
            this.result=data
        }
      
    },
    created() {
        this.getTotalMoney()
        this.loadData()
    }
}
</script>
